<template>
  <div class="humanhelp">
    <div class="humanhelp-container">
     <div class="help-title">
        <span>救助行动</span>
     </div>
     <div class="helpcontent">
         <!-- <div class="heathbanners">
           <Helpbanners :banners="list"></Helpbanners>
         </div> -->
         <Helpnews :newslist="list" :nodename="'jzxd'" @childByValue="childByValue"></Helpnews>
       </div>
    </div>
  </div>
</template>

<script>
import Helpbanners from '../Learnplatform/Lifeheath/components/heathbanners'
import Helpnews from '../Learnplatform/Lifeheath/components/heathnews'
import apiUrls from "@api"
export default {
  components : {
    Helpbanners,
    Helpnews
  },
  name: 'Humanhelp',
  data () {
    return {
      list: []
    }
  },
  created () {
    this.gethelpdate()
  },
  methods : {
    gethelpdate() {
     apiUrls.gethelpdate({node: 'jzxd'}).then(res=>{
        this.list = res.results.data
      })
    },
    childByValue (childByValue) {
      this.list = childByValue
    }
  }

}
</script>

<style lang="less" scoped>
.humanhelp {
  background-color: #f5f5f5;
  .humanhelp-container {
    width: 1200px;
    min-height: 400px;
    padding-bottom: 40px;
    margin: 0 auto;
    background-color: #f5f5f5;
    &::before {
        display: block;
        content: ".";
        clear: both;
        height: 0;
        overflow: hidden;
        visibility: hidden; 
    }
     .help-title  {
          margin-top: 10px;
          font-size: 22px;
          color: #333333;
          font-weight: bold;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 16px;
          span {
              &:nth-child(1) {
              display: flex;
              &::before {
              content: "";
              display: block;
              height: 20px;
              width: 20px;
              background: url('../../assets/img/title.png') no-repeat center center;
            }
          }
            &:nth-child(2) {
                color: #ABABAB;
                font-size: 17px;
            }
          }
        }
       .helpcontent {
		min-height: 300px;
        background-color: #ffffff;  
        box-shadow: 0px 0px 18px 0px rgba(14, 14, 14, 0.13);
        //padding-top: 56px;
        // .heathbanners {
        //   height: 480px;
        //   // background-color: pink;
        // }
      }
    }
    
}
</style>
