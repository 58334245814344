<template>
	<div class="heathnews">
		<div class="projectintroduct">
			<ul class="workinfo-list">
				<li v-for="(item, index) in newslist" :key="index" @click="$goto('/detailShow', { id: item.id, isremembrance })">
					<!-- <p>2020年高考防疫家长关键提示10条</p>
                <span>2020.04.20</span> -->
					<p>{{ item.title || '' }}</p>
					<span>{{ item.publishDate ? item.publishDate : '' | fordatatime }}</span>
				</li>
			</ul>
			<div class="pagination">
				<el-pagination
					class="bs-pagination"
					:page-size="size"
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="page"
					layout="total, sizes, prev, pager, next, jumper"
					:total="total"
					:key="page"
				></el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
import apiUrls from '@api';
export default {
	name: 'Heathnews',
	props: {
		newslist: {
			type: Array,
			default: () => []
		},
		nodename: {
			type: String,
			default: () => 'xxpt_smjk'
		},
		isremembrance: {
			type: Boolean,
			default: false
		}
	},
	filters: {
		fordatatime: value => {
			value = value.split('-').join('.');
			return value;
		}
	},
	data() {
		return {
			page: 1,
			size: 10,
			total: 0
		};
	},
	created() {
		this.handleCurrentChange();
	},
	methods: {
		handleSizeChange(val) {
			console.log(val);
			this.size = val;
			this.handleCurrentChange()
		},
		handleCurrentChange(val) {
			console.log(val);
			this.page = val;
			apiUrls.selfDynamicList({ node: this.nodename, page: this.page-1, size: this.size }).then(res => {
				this.total = res.results.pager.totalElements;
				this.newslist2 = res.results.data;
				this.$emit('childByValue', this.newslist2);
			});
		}
	}
};
</script>

<style lang="less" scoped>
.projectintroduct {
	min-height: 300px;
	background-color: #ffffff;
	overflow: hidden;
	padding: 40px 23px 40px 40px;
	box-shadow: 0px 0px 18px 0px rgba(14, 14, 14, 0.13);
	.workinfo-list {
		cursor: pointer;
		li {
			display: flex;
			justify-content: space-between;
			margin-bottom: 10px;
			padding: 10px 0;
			&:last-child {
				margin-bottom: 0;
			}
			p {
				color: #333333;
				&:hover {
					color: #d1292e;
				}
				display: flex;
				&::before {
					content: '';
					display: block;
					height: 16px;
					width: 26px;
					background: url('../../../../assets/img/list-little.png') no-repeat center center;
				}
			}
			span {
				color: #ababab;
			}
		}
	}
	.pagination {
		margin-top: 40px;
		.bs-pagination {
			width: 656px;
		}
	}
}
</style>
